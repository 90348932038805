import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { main } from '@/store/index';

import Axios from 'axios';
import VueGtag from 'vue-gtag-next';
import './assets/css/main.css';
import pkgJson from '../package.json';
import { createPinia } from 'pinia';

const pinia = createPinia();

const app = createApp(App);

export const latestDeploymentDate = "2025-03-14";
// const baseUrl = 'https://api-test.brilindia.com';

const baseUrl = 'https://s.brilindia.com';
console.log = function log() {};

// const baseUrl = 'http://localhost:3012';

const isAdmin = false;

app.config.globalProperties.$latestDeploymentDate = latestDeploymentDate;

app.config.globalProperties.$env = {
  isAdmin: isAdmin,
};

app.config.globalProperties.$http = Axios.create({
  baseURL: baseUrl,
  // timeout: 10000,
});

app.config.globalProperties.$adminTabsIndexMap = {
  Orders: 0,
  Users: 1,
  'User Commissions': 2,
  Products: 3,
  Carts: 4,
  Stats: 5,
  '1Bridge Orders': 6,
  '1Bridge Products': 7,
};

app.use(VueGtag, {
  config: {
    id: 'G-8WL3P9KZ5Z', // brilindia.com account
    params: {
      // user_id: localStorage['phone'],
    },
  },
  globalObjectName: 'bril-india-analytics',
});

// helper for getting <key, value> from local
const getFromLocal = (key) => {
  let value;
  if (localStorage[key]) {
    try {
      value = JSON.parse(localStorage[key]);
    } catch (e) {
      delete localStorage[key];
    }
  }
  return value;
};

const setLocal = (key, value) => {
  localStorage[key] = JSON.stringify(value);
};

app.config.globalProperties.$app_version = pkgJson.version;

app.config.globalProperties.$map = {
  openMap(lat, long) {
    window.open(`https://www.google.com/maps/place/${lat},${long}`, '_blank');
  },
};

app.config.globalProperties.$helpers = {
  isEmail(email) {
    /* eslint-disable no-useless-escape */
    return /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/.test(email);
  },
  isNameValid(name) {
    return /^[a-zA-Z][a-zA-Z]{2,}$/.test(name);
  },
  isPhoneNumber(phone) {
    return /^[0-9]{7,}$/.test(phone);
  },
};

app.config.globalProperties.$user = {
  logEvent(parameterName) {
    app.config.globalProperties.$gtag.event('select_content', {
      event_category: 'e-commerce',
      event_label: 'item_name',
      value: parameterName,
    });
  },

  //
  getOrderId(id) {
    return `BRIL000${1390 + id}`;
  },

  get1BridgeOrderId(id) {
    return `BRIL1B000${10 + id}`;
  },
  // googleInit() {
  //   const meta = document.createElement('meta');
  //   meta.name = 'google-signin-client_id';
  //   // meta.content = '721153306465-if0336t6fjgo49ffshnrv87thjgcd6ba.apps.googleusercontent.com';
  //   meta.content = '721153306465-saoab0lf0bh7r3u48qhtvkm6gpa0ki90.apps.googleusercontent.com';
  //   document.head.appendChild(meta);
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = 'https://apis.google.com/js/platform.js';
  //   document.head.appendChild(script);
  //   return script;
  // },
  googleInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
    return script;
  },
  googleSignOut(isDifferentUser) {
    const script = this.googleInit();
    script.onload = () => {
      console.log('script loaded');
      // eslint-disable-next-line
      const localGapi = gapi;
      localGapi.load('auth2', () => {
        localGapi.auth2.init().then(() => {
          const auth2 = localGapi.auth2.getAuthInstance();
          if (isDifferentUser) {
            auth2.disconnect();
          }
          if (auth2.isSignedIn.get()) {
            auth2.signOut().then(() => {
              console.log('User signed out from google.');
            });
          }
        });
      });
    };
  },
  logout(isDifferentUser) {
    console.log('isDifferentUser', isDifferentUser);
    this.googleSignOut(true); // hard-coding so that google login doesn't auto-login after logout
    main().clearVuex();
  },
  razorpayInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    document.head.appendChild(script);
    return script;
  },
  paytmInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/lwVswE24493279017685.js';
    document.head.appendChild(script);
    return script;
  },
};

app.config.globalProperties.$orderReviewTabs = {
  SUMMARY: 0,
  USER: 1,
  BILLING_ADDRESS: 2,
  SHIPPING_ADDRESS: 3,
  PAYMENT: 4,
};

app.config.globalProperties.$helpers = {
  regex: {
    password: /^.{6,}$/,
    firstname: /^[a-zA-Z -]{2,}$/,
    lastname: /^[a-zA-Z -]{1,}$/,
    fullname: /^[a-zA-Z -]{3,}$/,
    // eslint-disable-next-line
    email: /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
    mobile: /^[0-9/+]{10}$/, // exact 10 characters only
    generalText: /^.{3,}$/,
    shortText: /^.{1,}$/,
    pin: /^[0-9 ]{6}$/,
    city: /^[a-zA-Z& ]{3,}$/,
    state: /^[a-zA-Z& ]{3,}$/,
    country: /^[a-zA-Z ]{2,}$/,
    number: /^[0-9]{1,}$/,
    floatingNumber: /^[+-]?([0-9]*[.])?[0-9]+$/,
  },
  isEmail(email) {
    /* eslint-disable no-useless-escape */
    return /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/.test(email);
  },
  setLocal,
  getLocal: getFromLocal,
};

app.config.globalProperties.$date = {
  getDateTime(dateTimeStringTZ) {
    let date = new Date(dateTimeStringTZ).toString();
    return date.substring(4, 21);
  },
};

// app.use(store).use(router).mount('#app');
app.use(pinia).use(router).mount('#app');
