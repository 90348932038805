import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import ContactMe from '../views/ContactMe.vue';
import ConfirmSubscription from '../views/ConfirmSubscription.vue';
import { main } from '@/store/index';

const routes = [
  // prettier-ignore
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contact-me',
    name: 'ContactMe',
    component: ContactMe,
  },
  {
    path: '/about-me',
    name: 'About',
    component: About,
  },
  {
    path: '/confirm-subscription',
    name: 'ConfirmSubscription',
    component: ConfirmSubscription,
  },
  {
    path: '/logo',
    name: 'Logo',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "user" */ '../views/Logo.vue'),
  },
  {
    path: '/snap-login',
    name: 'snap-login',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "user" */ '../views/snap-login.vue'),
  },
  {
    path: '/user',
    name: 'User',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "user" */ '../views/User.vue'),
  },
  {
    path: '/user/profile',
    name: 'Profile',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    path: '/user/orders',
    name: 'Orders',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "orders" */ '../views/UserOrders.vue'),
  },
  {
    path: '/user/order/:orderId',
    name: 'UserOrderDetails',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "orders" */ '../views/OrderDetails.vue'),
  },
  {
    path: '/affiliate-reseller/commissions',
    name: 'Commissions',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "userAdmin" */ '../views/UserCommissions.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "admin" */ '../views/Admin.vue'),
  },
  {
    path: '/admin/order/:orderId',
    name: 'AdminOrderDetails',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "orders" */ '../views/OrderDetails.vue'),
  },
  {
    path: '/admin/users-for-approval',
    name: 'users-for-approval',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/users-for-approval.vue'),
  },
  {
    path: '/admin/orders',
    name: 'OrdersAdmin',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin-orders.vue'),
  },
  {
    path: '/admin/commissions',
    name: 'comissions',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/commissions.vue'),
  },
  {
    path: '/admin/products',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/Products.vue'),
  },
  // {
  //   path: '/admin/product/edit-add/:id?',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "admin" */ '../views/ProductEditAdd.vue')
  // },
  {
    path: '/admin/coupon-code',
    name: 'coupon',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "admin" */ '../views/admin/CouponCode.vue'),
  },
  {
    path: '/profile-advanced',
    // name: 'ProfileAdvanced',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "profile" */ '../views/ProfileAdvanced.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "forgotPassword" */ '../views/pass/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "forgotPassword" */ '../views/pass/ResetPassword.vue'),
  },
  {
    path: '/verify-signup',
    name: 'VerifySignUp',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "forgotPassword" */ '../views/pass/VerifySignUp.vue'),
  },

  {
    path: '/products/:category/:page',
    name: 'Products',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "products" */ '../views/Products.vue'),
  },
  {
    path: '/product/:mid/:title?',
    name: 'ProductDetail',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "products" */ '../views/ProductDetail.vue'),
  },
  {
    path: '/order-review',
    name: 'order-review',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "order-review" */ '../views/order-review.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "testimonials" */ '../views/Testimonials.vue'),
  },
  {
    path: '/order-details',
    name: 'OrderDetails',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "orderDetails" */ '../views/OrderDetails.vue'),
  },
  {
    path: '/products/search/:key',
    name: 'Search',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "products" */ '../views/Products.vue'),
  },
  {
    path: '/affiliates-join-us',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/AffiliatesJoinUs.vue'),
  },
  {
    path: '/resellers-join-us',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/ResellersJoinUs.vue'),
  },
  {
    path: '/retailers-join-us',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/RetailersJoinUs.vue'),
  },
  {
    path: '/distributors-join-us',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/DistributorsJoinUs.vue'),
  },
  {
    path: '/member-join-us',
    // prettier-ignore
    // component: () => import( /* webpackChunkName: "misc" */ '../views/AffiliatesJoinUs.vue'),
    component: () => import('../views/MembersJoinUs.vue'),
  },
  // {
  //   path: '/india-resellers',
  //   name: 'IndiaResellers',
  // prettier-ignore
  // component: () => import( /* webpackChunkName: "misc" */ '../views/IndiaResellers.vue'),
  // },
  {
    path: '/faq',
    name: 'Faq',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/Faq.vue'),
  },
  {
    path: '/business-opportunity',
    name: 'BusinessOpportunity',
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/BusinessOpportunity.vue'),
  },
  // footer privacy and other links
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    // component: PrivacyPolicy,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/footer/PrivacyPolicy.vue'),
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    // component: Disclaimer,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/footer/Disclaimer.vue'),
  },
  {
    path: '/cancellation-and-refund',
    name: 'Cancellation',
    // component: Cancellation,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/footer/Cancellation.vue'),
  },
  {
    path: '/shipping-and-delivery',
    name: 'Shipping',
    // component: Shipping,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/footer/Shipping.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/footer/Terms.vue'),
  },
  {
    path: '/admin/carts',
    name: 'admin-carts',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/admin-carts.vue'),
  },
  {
    path: '/admin/stats',
    name: 'admin-stats',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/admin-stats.vue'),
  },
  {
    path: '/admin/1bridge-orders',
    name: '1bridge-orders',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/admin_1bridge_orders.vue'),
  },
  {
    path: '/admin/1bridge-products',
    name: '1bridge-products',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "privacy" */ '../views/admin/1BridgeProducts.vue'),
  },
  // {
  //   path: '/test',
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "privacy" */ '../views/Test.vue')
  // },
  {
    path: '/bril-and-you',
    name: 'survey',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/Survey.vue'),
  },
  // {
  //   path: '/whatsapp',
  //   name: 'whatsapp',
  //   // component: Terms,
  //   // prettier-ignore
  //   component: () => import( /* webpackChunkName: "misc" */ '../views/Whatsapp.vue'),
  // },
  {
    path: '/get-free-book',
    name: 'free-book',
    // component: Terms,
    // prettier-ignore
    component: () => import( /* webpackChunkName: "misc" */ '../views/get-free-book.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const getCartSize = () => {
  const cart = JSON.parse(JSON.stringify(main().cart));
  console.log('cart ', cart);
  return cart.length;
};

router.beforeEach((to, from, next) => {
  console.log('path', to, from);
  window.scrollTo(0, 0);
  if (to.path === '/order-review') {
    if (getCartSize() === 0) {
      next('/');
      return;
    }
  }
  next();
});

export default router;
